<template>
  <div>
    <div v-if="isDataReady && formattedLayouts.length">
      <div class="grid-container">
        <div class="layout-label">Animated layout</div>
        <div class="grid" :style="gridStyles">
          <div v-for="cell in gameGrid" 
              :key="`${cell.row}-${cell.col}`"
              class="grid-cell"
              :id="`cell-${cell.row}-${cell.col}`"
              :style="{
                gridRow: cell.row + 1,
                gridColumn: cell.col + 1
              }">
            <div class="cell-content" :id="`content-${cell.row}-${cell.col}`">
              {{ cell.value }}
            </div>
          </div>
        </div>
        <br>
        <div v-if="formattedLayouts.length > 1" class="flex-buttons">
          <button v-if="!animationStarted" class="button" @click="startAnimation">Start</button>
          <button v-if="animationStarted" class="button" @click="replayAnimation">Replay</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'InstantLayoutAnimation',
  props: {
    lastTicketResult: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentLayoutIndex: 0,
      gameGrid: [],
      isDataReady: false,
      animationStarted: false
    };
  },
  computed: {
    parsedLayout() {
      if (!this.lastTicketResult?.layout) return null;
      return JSON.parse(this.lastTicketResult.layout);
    },

    gridStyles() {
      return {
        'grid-template-columns': `repeat(${this.parsedLayout.LayoutWidth}, 80px)`,
        'grid-template-rows': `repeat(${this.parsedLayout.LayoutHeight}, 80px)`,
        'width': `${this.parsedLayout.LayoutWidth * 80}px`,
        'height': `${this.parsedLayout.LayoutHeight * 80}px`
      }
    },

    formattedLayouts() {
      if (!this.parsedLayout) return [];
      
      const emojiMap = {
        'A': '🍎', 'B': '🍌', 'C': '🍒', 'D': '🍇',
        'E': '🍉', 'F': '🍓', 'G': '🍍', 'H': '🥝',
        'I': '🍋', 'MINI': '⭐', 'MEGA': '🏆',
        'TNT': '💣', 'W': '🃏'
      };


      return this.parsedLayout.Reveals.map(reveal => {
        const grid = Array.from({ length: this.parsedLayout.LayoutWidth }, (_, row) =>
          Array.from({ length: this.parsedLayout.LayoutHeight }, (_, col) => ({
            row,
            col,
            value: ''
          }))
        ).flat();

        reveal.LayoutCoordinates.forEach(coord => {
          const cell = grid.find(cell => cell.row === coord.Y && cell.col === coord.X);
          if (cell) {
            cell.value = emojiMap[coord.Symbol] || coord.Symbol;
          }
        });

        return {
          grid,
          cascadeEvent: reveal.CascadeEvent,
          winAmount: reveal.WinAmount,
          bonusAmount: reveal.BonusAmount
        };
      });
    }
  },
  methods: {
    createGrid() {
      if (!this.parsedLayout) return [];

      const grid = [];
      for (let row = 0; row < this.parsedLayout.LayoutWidth; row++) {
        for (let col = 0; col < this.parsedLayout.LayoutHeight; col++) {
          grid.push({
            row,
            col,
            value: ''
          });
        }
      }
      return grid;
    },

    async removeMatches(matches) {
      return new Promise(resolve => {
        matches.forEach(pos => {
          const [row, col] = pos.split('-');
          const cell = this.gameGrid.find(c => c.row === parseInt(row) && c.col === parseInt(col));
          const element = document.querySelector(`#content-${row}-${col}`);
          
          if (element && cell) {
            const tl = gsap.timeline();
            
            tl.to(element, {
              scale: 1.2,
              duration: 0.2
            })
            .to(element, {
              scale: 0.8,
              duration: 0.2
            })
            .to(element, {
              scale: 1.2,
              duration: 0.2
            })
            .to(element, {
              scale: 1,
              duration: 0.2
            })
            .to(element, {
              scale: 0,
              opacity: 0,
              duration: 0.5,
              onComplete: () => {
                cell.value = '';
                gsap.set(element, { clearProps: 'all' });
              }
            });
          }
        });
        setTimeout(resolve, 1400);
      });
    },

    async dropSymbols() {
      return new Promise(resolve => {
        let dropCount = 0;
        const emptyCells = this.gameGrid.filter(cell => !cell.value).length;
        
        for (let col = 0; col < this.parsedLayout.LayoutHeight; col++) {
          let emptySpaces = 0;
          const drops = [];
          
          for (let row = this.parsedLayout.LayoutWidth - 1; row >= 0; row--) {
            const cell = this.gameGrid.find(c => c.row === row && c.col === col);
            
            if (!cell.value) {
              emptySpaces++;
            } else if (emptySpaces > 0) {
              drops.push({
                fromCell: cell,
                spaces: emptySpaces,
                toRow: row + emptySpaces
              });
            }
          }
          
          drops.forEach(({fromCell, spaces, toRow}) => {
            const element = document.querySelector(`#content-${fromCell.row}-${fromCell.col}`);
            const targetCell = this.gameGrid.find(c => c.row === toRow && c.col === col);
            
            if (element && targetCell) {
              gsap.to(element, {
                y: spaces * 80,
                duration: 0.5,
                onComplete: () => {
                  targetCell.value = fromCell.value;
                  fromCell.value = '';
                  gsap.set(element, { clearProps: 'all' });
                  dropCount++;
                  if (dropCount === drops.length) {
                    resolve();
                  }
                }
              });
            }
          });
        }
        
        if (emptyCells === 0) {
          resolve();
        }
      });
    },

    async processLayouts() {
      let hasMatches = true;
      
      while (hasMatches) {
        const currentLayout = this.formattedLayouts[this.currentLayoutIndex];
        const matches = this.findMatches();
        
        if (currentLayout.cascadeEvent === 4 || currentLayout.cascadeEvent === 5) {
          await this.triggerTNTs();
          await this.dropSymbols();
        }

        if (matches.length) {
          await this.pause(200);
          await this.removeMatches(matches);
          await this.dropSymbols();
          
          const nextLayout = this.formattedLayouts[this.currentLayoutIndex + 1];
          if (nextLayout) {
            this.currentLayoutIndex++;
            await this.fillEmptySpaces();
          } else {
            hasMatches = false;
          }
        } else {
          if (currentLayout.cascadeEvent === 0) {
            hasMatches = false;
          } else {
            this.currentLayoutIndex++;
            await this.fillEmptySpaces();
          }
        }
      }
    },

    async fillEmptySpaces() {
      return new Promise(resolve => {
        const nextLayout = this.formattedLayouts[this.currentLayoutIndex];
        if (!nextLayout) {
          resolve();
          return;
        }

        let fillCount = 0;
        const emptyCells = this.gameGrid.filter(cell => !cell.value);

        emptyCells.forEach(cell => {
          const element = document.querySelector(`#content-${cell.row}-${cell.col}`);
          cell.value = nextLayout.grid.find(c => c.row === cell.row && c.col === cell.col).value;
          
          if (element) {
            gsap.fromTo(element,
              { y: -80, opacity: 0, scale: 1 },
              {
                y: 0,
                opacity: 1,
                duration: 0.5,
                onComplete: () => {
                  gsap.set(element, { clearProps: 'all' });
                  fillCount++;
                  if (fillCount === emptyCells.length) resolve();
                }
              }
            );
          }
        });

        if (emptyCells.length === 0) resolve();
      });
    },

    async replayAnimation() {
      this.currentLayoutIndex = 0;
      gsap.set('.cell-content', { clearProps: 'all' });
      this.populateGrid();
      await this.processLayouts();
    },

    populateGrid() {
      const layout = this.formattedLayouts[this.currentLayoutIndex].grid;
      this.gameGrid.forEach(cell => {
        const layoutCell = layout.find(c => c.row === cell.row && c.col === cell.col);
        cell.value = layoutCell ? layoutCell.value : '';
      });
    },

    findMatches() {
      const matches = new Set();
      this.gameGrid.forEach(cell => {
        if (cell.value && cell.value !== '💣') {
          const count = this.gameGrid.filter(c => c.value === cell.value).length;
          if (count >= 5) {
            this.gameGrid.forEach(c => {
              if (c.value === cell.value) matches.add(`${c.row}-${c.col}`);
            });
          }
        }
      });
      return Array.from(matches);
    },

    findTNTs() {
      const TNTs = [];
      this.gameGrid.forEach(cell => {
        if (cell.value === '💣') {
          TNTs.push(cell);
        }
      });
      return TNTs;
    },

    getAffectedCells(TNT) {
      const affected = new Set();
      
      for (let row = TNT.row - 1; row <= TNT.row + 1; row++) {
        for (let col = TNT.col - 1; col <= TNT.col + 1; col++) {
          if (row >= 0 && row < this.parsedLayout.LayoutWidth && col >= 0 && col < this.parsedLayout.LayoutHeight) {
            affected.add(`${row}-${col}`);
          }
        }
      }
      return Array.from(affected);
    },

    async triggerTNTs() {
      return new Promise((resolve, reject) => {
        const TNTs = this.findTNTs();
        if (!TNTs.length) {
          resolve();
          return;
        }

        const allAffectedCells = new Set();
        TNTs.forEach(TNT => {
          const affected = this.getAffectedCells(TNT);
          affected.forEach(cell => allAffectedCells.add(cell));
        });

        const timeline = gsap.timeline();
        
        TNTs.forEach(TNT => {
          const element = document.querySelector(`#content-${TNT.row}-${TNT.col}`);
          if (element) {
            timeline.to(element, {
              scale: 1.2,
              duration: 0.3,
              ease: "bounce.out"
            });
          }
        });

        timeline.to(TNTs.map(TNT => 
          document.querySelector(`#content-${TNT.row}-${TNT.col}`)
        ), {
          scale: 2,
          opacity: 0,
          duration: 0.5
        }).then(() => {
          Promise.all(
            Array.from(allAffectedCells).map(pos => {
              return new Promise(resolveCell => {
                const [row, col] = pos.split('-');
                const cell = this.gameGrid.find(c => c.row === parseInt(row) && c.col === parseInt(col));
                const element = document.querySelector(`#content-${row}-${col}`);
                
                if (element && cell) {
                  gsap.to(element, {
                    scale: 0,
                    opacity: 0,
                    duration: 0.3,
                    onComplete: () => {
                      cell.value = '';
                      gsap.set(element, { clearProps: 'all' });
                      resolveCell();
                    }
                  });
                } else {
                  resolveCell();
                }
              });
            })
          ).then(() => {
            setTimeout(resolve, 300);
          }).catch(reject);
        });
      });
    },

    pause(duration) {
      return new Promise(resolve => setTimeout(resolve, duration));
    },

    async startAnimation() {
      this.animationStarted = true;
      await this.processLayouts();
    }
  },
  mounted() {
    this.animationStarted = false;
    if (this.formattedLayouts.length) {
      this.gameGrid = this.createGrid();
      this.populateGrid();
      this.isDataReady = true;
    }
  }
};
</script>

<style>
.grid-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.grid {
  display: grid;
  grid-template-columns: repeat(v-bind(cols), 80px);
  grid-template-rows: repeat(v-bind(rows), 80px);
  gap: 2px;
}

.grid-cell {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
}

.cell-content {
  font-size: 75px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-buttons {
  display: flex;
  gap: 10px;
  margin-top: 1em;
}

button {
  cursor: pointer;
}
</style>