<template>
  <div>
    <div v-if="lastTicketResult && formattedLayouts.length">
      <div class="flex-wrapper">
        <div v-for="(layout, layoutIndex) in formattedLayouts" :key="layoutIndex" class="grid-container">
          <div class="layout-label">
            Layout {{ layoutIndex + 1 }}
            <span v-if="layout.cascadeEvent > 0" class="cascade-info">
              ({{ getCascadeEventName(layout.cascadeEvent) }})
            </span>
            <span v-if="layout.winAmount > 0" class="win-info">
              Win: {{ layout.winAmount }}
            </span>
            <span v-if="layout.bonusAmount > 0" class="bonus-info">
              Bonus: {{ layout.bonusAmount }}
            </span>
          </div>
          <div class="grid" :style="gridStyle">
            <div v-for="cell in layout.grid" 
                :key="`${cell.x}-${cell.y}`" 
                class="grid-cell"
                :style="getCellStyle(cell)">
              {{ cell.symbol }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstantLayoutGenerator',
  props: {
    lastTicketResult: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parsedLayout() {
      if (!this.lastTicketResult?.layout) return null;
      return JSON.parse(this.lastTicketResult.layout);
    },
    gridStyle() {
      if (!this.parsedLayout) return {};
      return {
        'grid-template-columns': `repeat(${this.parsedLayout.LayoutWidth}, 80px)`,
        'grid-template-rows': `repeat(${this.parsedLayout.LayoutHeight}, 80px)`
      };
    },
    formattedLayouts() {
      if (!this.parsedLayout) return [];
      
      const emojiMap = {
        'A': '🍎', 'B': '🍌', 'C': '🍒', 'D': '🍇',
        'E': '🍉', 'F': '🍓', 'G': '🍍', 'H': '🥝',
        'I': '🍋', 'MINI': '⭐', 'MEGA': '🏆',
        'TNT': '💣', 'W': '🃏'
      };

      return this.parsedLayout.Reveals.map(reveal => {
        // Create a 2D array for the grid
        const grid = [];
        for (let y = 0; y < this.parsedLayout.LayoutHeight; y++) {
          for (let x = 0; x < this.parsedLayout.LayoutWidth; x++) {
            grid.push({
              x,
              y,
              symbol: ''
            });
          }
        }

        // Fill in the symbols from the coordinates
        reveal.LayoutCoordinates.forEach(coord => {
          const cell = grid.find(cell => cell.x === coord.X && cell.y === coord.Y);
          if (cell) {
            cell.symbol = emojiMap[coord.Symbol] || coord.Symbol;
          }
        });

        return {
          grid,
          cascadeEvent: reveal.CascadeEvent,
          winAmount: reveal.WinAmount,
          bonusAmount: reveal.BonusAmount
        };
      });
    }
  },
  methods: {
    getCascadeEventName(event) {
      const events = {
        0: 'None',
        1: 'Win',
        2: 'Bonus',
        3: 'Bonus & Win',
        4: 'Bomb',
        5: 'Bonus & Bomb'
      };
      return events[event] || 'Unknown';
    },
    getCellStyle(cell) {
      return {
        'grid-row': cell.y + 1,
        'grid-column': cell.x + 1
      };
    }
  }
};
</script>

<style>
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
}

.grid-container {
  margin-bottom: 20px;
}

.layout-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.grid {
  display: grid;
  gap: 2px;
}

.grid-cell {
  width: 80px;
  height: 80px;
  display: flex;
  font-size: 75px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}

.cascade-info {
  font-size: 0.8em;
  color: #666;
  margin-left: 8px;
}

.win-info {
  color: green;
  font-size: 0.9em;
  margin-top: 4px;
}

.bonus-info {
  color: blue;
  font-size: 0.9em;
  margin-top: 4px;
}
</style>